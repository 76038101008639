<template>
  <div class="pd-x-5" v-loading="chartLoading">
    <div>
      <h3>ภาพรวมในแต่ละด้าน</h3>
      <div>
        <el-row>
          <el-col :span="12">
            <div>
              <h4 class="mg-b-0">ขนาดธุรกิจ</h4>
              <div v-if="!chartLoading">
                <barChart
                  :label="['Small', 'Medium', 'Large']"
                  :height="'270px'"
                  :data="businessSize"
                />
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <h4 class="mg-b-0">ลักษณะธุรกิจ</h4>
            <div v-if="!chartLoading">
              <barChart
                :label="[
                  'ธุรกิจบริการ',
                  'ธุรกิจอุตสาหกรรม\n หรือ \nธุรกิจประเภทผลิต',
                  'ธุรกิจพาณิชยกรรม\n หรือ \nธุรกิจแบบซื้อมาขายไป',
                ]"
                :height="'300px'"
                :data="businessType"
              />
            </div>
          </el-col>
          <!-- <el-col :span="8">
                        <h4>ประเภทอุตสาหกรรม </h4>
                        <div v-if="!chartLoading">
                            <barChart
                                :label="['Small','Medium','Large']"
                                :height="'190px'"
                            />
                        </div>
                    </el-col> -->
        </el-row>
      </div>
    </div>
    <div>
      <el-row>
        <el-col :span="12">
          <h4 class="mg-b-0">รายได้ แบ่งตามประเภทธุรกิจ</h4>
          <div v-if="!chartLoading">
            <barChart
              :label="[
                'ธุรกิจบริการ',
                'ธุรกิจอุตสาหกรรม\n หรือ \nธุรกิจประเภทผลิต',
                'ธุรกิจพาณิชยกรรม\n หรือ \nธุรกิจแบบซื้อมาขายไป',
              ]"
              :height="'300px'"
              :data="revenueBybusinessType"
              :legend="legendRevenue"
            />
          </div>
        </el-col>
        <el-col :span="12">
          <h4 class="mg-b-0">สัดส่วนในการส่งออก</h4>
          <div v-if="!chartLoading">
            <PieChart :data="exportProportion" />
          </div>
        </el-col>
      </el-row>
    </div>
    <div>
      <el-row>
        <el-col :span="12">
          <h4 class="mg-b-0">
            ความสม่ำเสมอของการสั่งซื้อจากผู้ซื้อต่างประเทศรายเดิม
          </h4>
          <div v-if="!chartLoading">
            <barChart
              :label="[
                'สม่ำเสมอ \nเป็นระยะเวลา \n1-2 ปี',
                'เป็นครั้งคราวเป็น\nระยะเวลา \n1-2 ปี',
                'สม่ำเสมอ\nเป็นระยะเวลา\nมากกว่า 2 ปี',
                'เป็นครั้งคราว\nเป็นระยะเวลา\nมากกว่า 2 ปี',
              ]"
              :height="'300px'"
              :data="orderConsistency"
            />
          </div>
        </el-col>
        <el-col :span="12">
          <h4 class="mg-b-0">ความถี่ในการส่งออกโดยเฉลี่ย</h4>
          <div v-if="!chartLoading">
            <barChart
              :label="[
                'มากกว่า\n 1 ครั้งต่อ\nเดือน',
                'มากกว่า\n 1 ครั้งต่อ\nไตรมาส',
                'มากกว่า\n 1 ครั้งต่อ\n 6 เดือน',
                'มากกว่า\n 1 ครั้ง\nต่อปี',
              ]"
              :height="'300px'"
              :data="exportFrequency"
            />
          </div>
        </el-col>
      </el-row>
    </div>
    <div>
      <el-row>
        <el-col :span="18">
          <h4 class="mg-b-0">จำนวนประเทศที่ทำการส่งออก</h4>
          <div v-if="!chartLoading">
            <BarYChart
              :label="['มากกว่า 5 ประเทศ', '3-5 ประเทศ', '1-2 ประเทศ']"
              :height="'400px'"
              :data="country"
            />
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import echarts from "echarts";
import barChart from "@/echart/BarChart";
import RadarChart from "@/echart/Radar";
import PieChart from "@/echart/PieChart";
import BarYChart from "@/echart/BarYChart";
import { HTTP } from "@/service/axios";
import numeral from "numeral";
export default {
  props: {
    filter: {
      type: Object,
      default: ""
    }
  },
  computed: {
    token() {
      return this.$store.state.token;
    }
  },
  components: {
    PieChart,
    barChart,
    RadarChart,
    BarYChart
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      let obj = {
        year: this.filter.year == "" ? "all" : this.filter.year,
        businessSize:
          this.filter.businessSize == "" ? "all" : this.filter.businessSize,
        businessType:
          this.filter.businessType == "" ? "all" : this.filter.businessType,
        industryType:
          this.filter.industryType == "" ? "all" : this.filter.industryType,
        cateIndustryType:
          this.filter.cateIndustryType == ""
            ? "all"
            : this.filter.cateIndustryType,
        memberOf: this.filter.memberOf == "" ? "all" : this.filter.memberOf
      };
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      await HTTP.post(`/dashboard`, obj)
        .then(res => {
          //console.log(`/dashboard `, res);
          if (res.data.success) {
            let barSize = "65%";
            let data = res.data.obj;
            this.businessSize.push({
              name: "",
              type: "bar",
              barMaxWidth: barSize,
              data: [
                data.businessSize.small,
                data.businessSize.meduim,
                data.businessSize.large
              ]
            });

            this.businessType.push({
              name: "",
              type: "bar",
              barMaxWidth: barSize,
              data: [
                data.businessType.service,
                data.businessType.industry,
                data.businessType.commerce
              ]
            });

            let year = [];
            let max;
            let min;

            data.revenueBybusinessType.map(data => {
              let index = year.filter(a => a == data.year);
              if (index.length === 0) {
                year.push(Number(data.year));
              }
            });

            min = Math.min(...year);
            max = Math.max(...year);
            let bizz = [];
            for (var i = min; i <= max; i++) {
              this.legendRevenue.push(i.toString());
              bizz.push({
                name: i,
                type: "bar",
                barMaxWidth: barSize,
                data: []
              });
            }

            for (var i = 0; i < bizz.length; i++) {
              let filter = data.revenueBybusinessType.filter(
                a => a.year == bizz[i].name
              );
              filter = filter.sort((a, b) => {
                let modifier = 1;
                if (a.businessType < b.businessType) return -1 * modifier;
                if (a.businessType > b.businessType) return 1 * modifier;
                return 0;
              });
              //console.log("filter", filter);
              filter.map(value => {
                bizz[i].data.push(value.totalRevuenus);
              });
            }

            this.revenueBybusinessType = bizz;

            let obj = [
              {
                value: data.exportProportion.totalSales0,
                name: "0% ของยอดขายทั้งหมด"
              },
              {
                value: data.exportProportion.totalSales20,
                name: "1%-20% ของยอดขายทั้งหมด"
              },
              {
                value: data.exportProportion.totalSales40,
                name: "21%-40% ของยอดขายทั้งหมด"
              },
              {
                value: data.exportProportion.totalSalesMore40,
                name: "เกิน 40% ของยอดขายทั้งหมด"
              }
            ];

            this.exportProportion = obj;

            this.orderConsistency.push({
              name: "",
              type: "bar",
              barMaxWidth: barSize,
              data: [
                data.orderConsistency.consistency,
                data.orderConsistency.sometimes,
                data.orderConsistency.consistency2Years,
                data.orderConsistency.sometimes2Years
              ]
            });

            this.exportFrequency.push({
              name: "",
              type: "bar",
              barMaxWidth: barSize,
              data: [
                data.exportFrequency.moreThan1TimePerMonth,
                data.exportFrequency.moreThan1TimePerQuarter,
                data.exportFrequency.moreThan1TimePer6Months,
                data.exportFrequency.moreThan1TimePer1Year
              ]
            });

            this.country.push({
              name: "",
              type: "bar",
              barMaxWidth: barSize,
              data: [
                data.country.moreThan5,
                data.country.country5,
                data.country.country2
              ]
            });
          }
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("fetchResult error", e);
        })
        .finally(() => {
          this.loading = false;
          setTimeout(() => {
            this.chartLoading = false;
          }, 800);
        });
    }
  },
  data() {
    return {
      businessSize: [],
      businessType: [],
      revenueBybusinessType: [],
      legendRevenue: [],
      exportProportion: [],
      orderConsistency: [],
      exportFrequency: [],
      country: [],
      ll: true,
      loading: true,
      chartLoading: true,
      widthMax: window.innerWidth,
      active: "ปัจจัยในแต่ละด้าน",
      chartDataFactors: {},
      chartDataIndicators: {},
      chartDataFactorLv: {},
      chartData: [
        {
          value: [],
          name: "ภาพรวมแบบประเมิน"
        }
      ],
      indicator: [
        { name: "ด้านกลยุทธ์บริหารธุรกิจเพื่อการส่งออก", max: 5 },
        { name: "ด้านกระบวนการผลิตและกระจายสินค้า", max: 5 },
        { name: "ด้านแผนการตลาด", max: 5 },
        { name: "ด้านแผนการเงินและการจัดการความเสี่ยงในธุรกิจ", max: 5 },
        { name: "ด้านลักษณะผลิตภัณฑ์และการพัฒนาผลิตภัณฑ์", max: 5 }
      ]
    };
  }
};
</script>