<template>
  <div :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import * as echarts from "echarts";
require("echarts/theme/macarons"); // echarts theme
const animationDuration = 2000;
export default {
  props: {
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "300px"
    },
    label: {
      type: Array,
      default: () => []
    },
    legend: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    },
    xAxisSize: {
      type: Number,
      default: 20
    },
    yAxisSize: {
      type: Number,
      default: 20
    }
  },
  data() {
    return {
      chart: null
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, "macarons");
      this.chart.setOption({
        tooltip: {
          trigger: "axis",
          textStyle: {
            color: "#fff",
            fontSize: 20
          },
          axisPointer: {
            type: "shadow"
          },
          formatter: "{b}: {c}"
        },
        legend: {
          data: this.legend
        },
        textStyle: {
          fontFamily: "db_heaventregular",
          fontSize: 30
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          axisLabel: {
            fontSize: this.xAxisSize
          },
          type: "value",
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          axisLabel: {
            fontSize: this.yAxisSize
          },
          type: "category",
          data: this.label
        },
        series: [
          {
            type: "bar",
            barWidth: "50px",
            name: this.data[0].name,
            data: this.data[0].data,
            itemStyle: {
              color: "#07509C"
            }
          }
        ]
      });
    }
  }
};
</script>