<template>
  <DashboardAdminTemplate>
    <div class="pd-x-5 pd-b-3">
      <h2>ภาพรวม</h2>
      <div class="bordered rounded pd-2 pd-t-4 pd-b-5 mg-b-3">
        <el-form label-position="top" class="is-flex flex-warp">
          <el-form-item label="ปีที่ทำแบบประเมิน" class="mg-r-6 padding-less">
            <el-select
              @change="changeFuction()"
              v-model="filters.year"
              clearable
              placeholder="ปีที่ทำแบบประเมิน"
            >
              <el-option
                v-for="(item, index) in years"
                :key="index"
                :label="item.lable"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="ขนาดของธุรกิจ" class="mg-r-6 padding-less">
            <el-select
              @change="changeFuction()"
              v-model="filters.businessSize"
              clearable
              placeholder="ทั้งหมด"
            >
              <el-option label="ธุรกิจขนาดเล็ก (S)" value="S"> </el-option>
              <el-option label="ธุรกิจขนาดกลาง (M)" value="M"> </el-option>
              <el-option label="ธุรกิจขนาดใหญ่ (L)" value="L"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="ลักษณะธุรกิจ" class="mg-r-6 padding-less">
            <el-select
              @change="changeFuction()"
              v-model="filters.businessType"
              clearable
              placeholder="ทั้งหมด"
            >
              <el-option
                v-for="item in businessType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="ประเภทอุตสาหกรรม" class="mg-r-6 padding-less">
            <el-select
              @change="changeFuction(), (filters.industryType = '')"
              v-model="filters.cateIndustryType"
              clearable
              filterable
              placeholder="ทั้งหมด"
            >
              <el-option
                v-for="item in industryType"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="ประเภทย่อยของอุตสาหกรรม"
            class="mg-r-6 padding-less"
          >
            <el-select
              @change="changeFuction()"
              v-model="filters.industryType"
              clearable
              filterable
              placeholder="ทั้งหมด"
            >
              <el-option
                v-for="item in subIndustry"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="หน่วยงานที่เป็นสมาชิก"
            prop="memberOf"
            class="mg-r-6 padding-less"
          >
            <el-select
              v-model="filters.memberOf"
              placeholder="กรุณาเลือกหน่วยงาน"
              clearable
              class="w-100"
              @change="changeFuction()"
            >
              <el-option
                v-for="data in memberOrganizeOption"
                :label="data.label"
                :value="data.value"
                :key="data.label"
                ><div class="text-over">{{ data.label }}</div></el-option
              >
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <el-tabs type="border-card" v-model="active">
        <el-tab-pane
          label="ภาพรวมของธุรกิจ"
          name="ภาพรวมของธุรกิจ"
          class="pd-b-2"
        >
          <businessOverview
            :filter="filters"
            v-if="active === 'ภาพรวมของธุรกิจ'"
          />
        </el-tab-pane>
        <el-tab-pane label="ภาพรวมคำแนะนำ" name="ภาพรวมคำแนะนำ"
          ><suggestOverview :filter="filters" v-if="active === 'ภาพรวมคำแนะนำ'"
        /></el-tab-pane>
      </el-tabs>
    </div>
  </DashboardAdminTemplate>
</template>
<script>
import DashboardAdminTemplate from "@/template/DashboardAdminTemplate";
import suggestOverview from "@/components/dashboard/SuggestOverview";
import businessOverview from "@/components/dashboard/BusinessOverview";
import { HTTP } from "@/service/axios";
export default {
  components: {
    DashboardAdminTemplate,
    businessOverview,
    suggestOverview
  },
  computed: {
    memberOrganizeOption() {
      return this.$store.state.memberOfOrganize;
    },
    subIndustry() {
      let filterSub = this.$store.state.industryType.filter(
        a =>
          this.filters.cateIndustryType != "" &&
          a.id === this.filters.cateIndustryType
      );
      if (filterSub.length > 0) {
        return filterSub[0].subIndustry;
      } else {
        return [];
      }
    },
    businessType() {
      return this.$store.state.businessType;
    },
    industryType() {
      let fitler = this.$store.state.industryType;

      fitler = fitler.filter(a => a.id != 26 && a.id != 27);

      return fitler;
    },
    token() {
      return this.$store.state.token;
    }
  },
  methods: {
    changeFuction() {
      let active = this.active;
      this.active = "";
      setTimeout(() => {
        this.active = active;
      }, 400);
    },
    getYear ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.get(`years`)
        .then(res => {
          res.data.obj.map(f => {
            this.years.push({ label: f, value: f });
          });
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log(e);
        });
    }
  },
  mounted() {
    this.getYear();
  },
  data() {
    return {
      url: process.env.VUE_APP_BASE_URL,
      active: "ภาพรวมของธุรกิจ",
      years: [],
      filters: {
        memberOf: "",
        year: "",
        businessSize: "",
        businessType: "",
        cateIndustryType: "",
        industryType: ""
      }
    };
  }
};
</script>   