<template>
  <div v-if="showGraph">
    <el-row>
      <el-col :span="24" class="pd-x-5">
        <h3>ภาพรวมในแต่ละด้าน</h3>
        <div v-loading="chartLoading">
          <div v-if="!chartLoading">
            <radar-chart
              :data="chartData"
              :indicator="indicator"
              :axisLabel="true"
              :nameFontSize="widthMax > 768 ? 17 : 14"
              radius="50%"
              :axisSize="14"
              :height="widthMax > 768 ? '380px' : '340px'"
              :showTooltip="true"
              :tooltipPosition="[-100, 20]"
            />
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-tabs type="border-card" v-model="active">
          <el-tab-pane
            label="ปัจจัยในแต่ละด้าน"
            name="ปัจจัยในแต่ละด้าน"
            class="pd-b-4"
          >
            <div class="pd-5" v-if="!chartLoading">
              <el-row>
                <el-col
                  :span="24"
                  :md="widthMax <= 1366 ? 12 : 8"
                  class="pd-x-5 mg-t-3"
                  v-for="(data, index) in indicatorSort"
                  :key="index"
                >
                  <div
                    class="bordered pd-5"
                    v-loading="chartLoading"
                    style="min-height: 400px"
                  >
                    <div
                      class="
                        border-bottom
                        is-flex
                        js-between
                        pd-y-5 pd-x-6
                        font-22
                      "
                    >
                      <div>{{ data.name }}</div>
                      <div>
                        <small class="text-grey">ระดับ</small>
                        <b class="mg-l-7">{{
                          chartDataFactorLv[data.id] | dotRoundDown
                        }}</b>
                      </div>
                    </div>

                    <div v-if="!chartLoading">
                      <radar-chart
                        :data="flipData(chartDataFactors[data.id])"
                        :indicator="chartDataIndicators[data.id]"
                        :nameFontSize="widthMax > 768 ? 14 : 14"
                        radius="50%"
                        :axisLabel="true"
                        :height="widthMax > 768 ? '320px' : '300px'"
                        :showTooltip="true"
                        :tooltipPosition="[-100, 20]"
                        :revert="false"
                        :axisSize="14"
                      />
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-tab-pane>
          <!-- <el-tab-pane label="ธุรกิจขนาดกลาง (M)" name="ธุรกิจขนาดกลาง (M)">
            <div class="pd-5" v-if="active == 'ธุรกิจขนาดกลาง (M)'">
              <el-row>
                <el-col
                  :span="24"
                  :md="8"
                  class="pd-x-5 mg-t-3"
                  v-for="data in 5"
                  :key="data"
                >
                  <div class="bordered pd-7">
                    <h3>ด้านกลยุทธ์บริหารธุรกิจเพื่อการส่งออก</h3>
                    <RadarChart
                      :data="chartData"
                      :indicator="indicator"
                      :nameFontSize="widthMax > 768 ? 12 : 10"
                      radius="50%"
                      :height="widthMax > 768 ? '320px' : '300px'"
                    />
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-tab-pane>
          <el-tab-pane label="ธุรกิจขนาดกลาง (L)" name="ธุรกิจขนาดกลาง (L)">
            <div class="pd-5" v-if="active == 'ธุรกิจขนาดกลาง (L)'">
              <el-row>
                <el-col
                  :span="24"
                  :md="8"
                  class="pd-x-5 mg-t-3"
                  v-for="data in 5"
                  :key="data"
                >
                  <div class="bordered pd-7">
                    <h3>ด้านกลยุทธ์บริหารธุรกิจเพื่อการส่งออก</h3>
                    <RadarChart
                      :data="chartFactorData"
                      :indicator="indicator"
                      :nameFontSize="widthMax > 768 ? 12 : 10"
                      radius="50%"
                      :height="widthMax > 768 ? '320px' : '300px'"
                    />
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-tab-pane> -->
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import echarts from "echarts";
import RadarChart from "@/echart/Radar";
import { HTTP } from "@/service/axios";
export default {
  props: {
    filter: {
      type: Object,
      default: ""
    }
  },
  components: {
    RadarChart
  },
  data() {
    return {
      ll: true,
      loading: true,
      chartLoading: true,
      widthMax: window.innerWidth,
      active: "ปัจจัยในแต่ละด้าน",
      chartDataFactors: {},
      chartDataIndicators: {},
      chartDataFactorLv: {},
      chartData: [
        {
          value: [],
          name: "ภาพรวมแบบประเมิน"
        }
      ],
      indicator: [],
      showGraph: true
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    indicatorSort() {
      let data = JSON.parse(JSON.stringify(this.indicator));
      return data.sort((a, b) => {
        if (a.id === "cat1" || b.id === "cat1") return -1;
        if (a.id > b.id) return 1;
        if (a.id < b.id) return -1;
        return 0;
      });
    }
  },
  async mounted() {
    await this.fetchData();
    window.addEventListener("resize", this.resize);
    setTimeout(() => {
      this.ll = false;
    }, 1500);
  },
  methods: {
    resize() {
      this.showGraph = false;
      this.widthMax = window.innerWidth;
      setTimeout(() => {
        this.showGraph = true;
      }, 200);
    },
    async fetchData() {
      let obj = {
        year: this.filter.year === "" ? "all" : this.filter.year,
        businessSize:
          this.filter.businessSize === "" ? "all" : this.filter.businessSize,
        businessType:
          this.filter.businessType === "" ? "all" : this.filter.businessType,
        industryType:
          this.filter.industryType === "" ? "all" : this.filter.industryType,
        cateIndustryType:
          this.filter.cateIndustryType === ""
            ? "all"
            : this.filter.cateIndustryType,
        memberOf: this.filter.memberOf === "" ? "all" : this.filter.memberOf
      };

      HTTP.defaults.headers.common.Authorization = `Bearer ${ this.token }`;
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      await HTTP.post(`dashboard/recommend`, obj)
        .then(async res => {
          //console.log("fetchData", res);
          if (res.data.success) {
            let dataChart = this.getDataChart(res.data.obj);
            await this.chartLevel(dataChart);
          }
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("fetchResult error", e);
        })
        .finally(() => {
          this.loading = false;
          setTimeout(() => {
            this.chartLoading = false;
          }, 800);
        });
    },
    getDataChart(data) {
      if (this.filter.businessSize !== "") {
        return data[this.filter.businessSize];
      } else {
        let obj = data["S"];
        Object.keys(data).forEach((size, index) => {
          if (size !== "S") {
            let series = index === 2 ? 3 : 1;
            Object.keys(data[size]).forEach(cat => {
              for (
                let index = 0;
                index < data[size][cat].factor.length;
                index++
              ) {
                obj[cat].factor[index].point =
                  (obj[cat].factor[index].point +
                    data[size][cat].factor[index].point) /
                  series;
              }
            });
          }
        });

        return obj;
      }
    },
    chartLevel(data) {
      let chartDataInput = data;
      let chartDataOutput = [];
      let chartDataTemp = [];
      let chartIndicator = [];
      //console.log("indicator", this.chartDataIndicators);
      for (let i = 1; i <= Object.keys(chartDataInput).length; i++) {
        let key = i == 1 ? 1 : i == 2 ? 5 : i == 3 ? 4 : i == 4 ? 3 : 2;
        //console.log("key", key);
        if (
          typeof chartDataInput["cate" + key] != "undefined" &&
          chartDataInput["cate" + key].name
        ) {
          let cateName = "cate" + key;
          chartDataOutput[cateName] = chartDataInput["cate" + key].factor;

          chartDataTemp = chartDataOutput["cate" + key].map((val, index) => {
            if (index == 0) {
              return Number(val.point !== null ? val.point : "");
            } else {
              return Number(
                chartDataOutput["cate" + key][
                  chartDataOutput["cate" + key].length - index
                ].point !== null
                  ? val.point
                  : ""
              );
            }
          });
          chartIndicator[cateName] = [];
          chartDataOutput[cateName].map((val, index) => {
            if (index == 0) {
              chartIndicator[cateName].push({
                name: this.addNewLine(
                  val.questionName,
                  index == 0 ? 30 : this.widthMax < 376 ? 10 : 14
                ),
                max: 5
              });
            } else {
              chartIndicator[cateName].push({
                name: this.addNewLine(
                  chartDataOutput[cateName][
                    chartDataOutput[cateName].length - index
                  ].questionName,
                  index == 0 ? 30 : this.widthMax < 376 ? 10 : 14
                ),
                max: 5
              });
            }
          });

          chartDataOutput[cateName] = [
            {
              value: chartDataTemp,
              name: chartDataInput["cate" + key].name
            }
          ];

          this.chartDataFactors[cateName] = chartDataOutput[cateName];
          this.chartDataIndicators[cateName] = chartIndicator[cateName];
          this.chartDataFactorLv[cateName] = this.$options.filters.dotRoundDown(
            Number(
              chartDataTemp.reduce((sum, item) => sum + Number(item), 0) /
                chartDataTemp.length
            )
          );
        }
        this.indicator.push({
          id: "cate" + key,
          name: chartDataInput["cate" + key].name,
          max: 5
        });
      }
      // console.log("this.chartDataFactorLv", this.chartDataFactorLv);

      this.chartData[0].value = Object.values(this.chartDataFactorLv).map(
        item => item
      );
    },
    flipData(datas) {
      //console.log("datas", datas);
      let data = [];
      for (let index = 0; index < datas[0].value.length; index++) {
        let i = 0;
        if (index > 0) {
          i = 1 + (datas[0].value.length - (1 + index));
        }
        //console.log(i);
        data.push(datas[0].value[i]);
      }

      return [{ name: datas[0].name, value: data }];
    }
  }
};
</script>
