<template>
  <div :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import numeral from "numeral";
import * as echarts from "echarts";
require("echarts/theme/macarons"); // echarts theme
const animationDuration = 2000;
export default {
  props: {
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "300px"
    },
    label: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    },
    legend: {
      type: Array,
      default: () => []
    },
    xAxisSize: {
      type: Number,
      default: 20
    }
  },
  data() {
    return {
      chart: null
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, "macarons");
      this.chart.setOption({
        toolbox: {
          show: true
        },
        legend: {
          data: this.legend
        },
        tooltip: {
          trigger: "axis",
          textStyle: {
            color: "#fff",
            fontSize: 20
          },
          axisPointer: {
            type: "shadow"
          },
          // formatter: "{b} <br>{a}: {c}",
          formatter: params => {
            params.sort(function(a, b) {
              // Sort params by value size first
              return parseInt(b.value) - parseInt(a.value);
            });

            var output = params[0].name + "<br/>";
            for (var i = 0; i < params.length; i++) {
              output +=
                params[i].marker +
                params[i].seriesName +
                (params[i].seriesName ? ": " : "") +
                params[i].value.toLocaleString();

              if (i != params.length - 1) {
                // Append a <br/> tag if not last in loop
                output += "<br/>";
              }
            }
            return output;
          }
        },
        textStyle: {
          fontFamily: "db_heaventregular",
          fontSize: 30
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",
            data: this.label,
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              fontSize: this.xAxisSize,
              formatter: function(value, index) {
                return numeral(value).format("0a");
              }
            }
          }
        ],
        xAxis: [
          {
            type: "category",
            axisLabel: {
              fontSize: this.xAxisSize
            },
            data: this.label
          }
        ],
        series: [
          {
            type: "bar",
            barWidth: "50px",
            name: this.data[0].name,
            data: this.data[0].data,
            itemStyle: {
              color: "#07509C"
            }
          }
        ]
      });
    }
  }
};
</script>